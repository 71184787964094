<template>
  <v-container v-if="unlocked">
    <v-row no-gutters>
      <v-col cols="3">
        <v-select
          v-model="createMode"
          label="Game Mode"
          :items="createModes"
          @change="setRegions"
        />
        <v-select
          v-if="this.regions.length>0"
          v-model="inviteRegion"
          label="Region"
          :items="inviteRegions"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="inviteName"
          class="py-2"
          label="Name"
          name="alias"
          outlined
          single-line
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="nSlots"
          class="py-2"
          label="Slots"
          name="price"
          outlined
          single-line
        />
      </v-col>
      <v-col cols="3">
        <v-btn @click="createInvite">CREATE</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapState,
    mapActions,
  } from 'vuex'
  export default {
    name: 'InviteBar',
    data () {
      return {
        createMode: 0,
        inviteName: '',
        inviteRegion: '',
        nSlots: 8,
        regions: [],
      }
    },
    computed: {
      ...mapState('platform', ['games']),
      ...mapState('wallet', ['unlocked']),
      // ...mapState('node', ['gameModes', 'regions']),
      gameModes () {
        return []
      },
      createModes () {
        var r = []
        if (!Object.keys(this.games).length) return r
        for (var g of Object.keys(this.games)) {
          r.push({ text: this.games[g].name, value: g })
        }
        return r
      },
      inviteRegions () {
        return this.regions
      },
    },
    methods: {
      ...mapActions('wallet', ['sendMsg']),
      setRegions () {
        var regions = this.games[this.createMode].regions
        var r = []
        if (regions) {
          for (var g of Object.keys(regions)) {
            r.push({ text: regions[g], value: g })
          }
        }
        this.regions = r
      },
      createInvite () {
        // do x
        console.log('do create..')
        var region = this.inviteRegion
        if (region === 0) return console.log('plz select region..')
        var createMode = this.createMode
        var createAlias = this.inviteName
        var nSlots = parseInt(this.nSlots)
        // check if mode is valid?
        if (!this.gameModes[createMode]) {
          createMode = 0
          // return console.log('invalid mode?')
        }
        // var isPublic = true // this.cbPublic
        var options = {
          autoReady: true,
          /* maxPlayers: this.maxPlayers,
          expireTime: this.expireTime,
          closeTime: this.closeTime,
          password: this.editPassword,
          minBet: this.minBet,
          maxBet: this.maxBet,
          autoReady: this.cbAutoReady,
          isPublic, */
        }
        this.sendMsg(JSON.stringify({ msg: 'create_tournament', createMode, region, createAlias, nSlots, options })) // tell me mode, alias and price, also options..
      },
    },
  }
</script>
